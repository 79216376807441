<template>
  <div class="">
    <img src="@/assets/bg.jpg" class="bg" />
    <v-dialog v-model="dialog" persistent max-width="600px" min-width="360px">
      <v-card class="px-4" v-if="pasien">
        <v-row>
          <v-col cols="4">
            <v-img src="@/assets/logo.png" width="162"></v-img>
          </v-col>
          <v-col>
            <v-card-title>Kartu Pasien</v-card-title>
            <v-card-subtitle
              >Kartu ini sudah terverifikasi dalam sistem kami</v-card-subtitle
            >
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-card-text class="mt-7">
          <v-row>
            <v-col cols="4" class="py-0 pr-0">
              <h1 class="subtitle-2 black--text font-weight-black">Nama</h1>
            </v-col>
            <div class="w-100 subtitle-2 black--text font-weight-black">:</div>
            <v-col class="py-0 pr-0">
              <h1 class="subtitle-2 black--text font-weight-black">
                {{ pasien.name }}
              </h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" class="py-0 pr-0">
              <h1 class="subtitle-2 black--text">Kewarganegaraan</h1>
            </v-col>
            <div class="w-100 subtitle-2 black--text">:</div>
            <v-col class="py-0 pr-0">
              <h1 class="subtitle-2 black--text">
                {{ pasien.nationality }}
              </h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" class="py-0 pr-0">
              <h1 class="subtitle-2 black--text">Nomor KTP</h1>
            </v-col>
            <div class="w-100 subtitle-2 black--text">:</div>
            <v-col class="py-0 pr-0">
              <h1 class="subtitle-2 black--text">
                {{ pasien.identityNumber }}
              </h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" class="py-0 pr-0">
              <h1 class="subtitle-2 black--text">Nomor Paspor</h1>
            </v-col>
            <div class="w-100 subtitle-2 black--text">:</div>
            <v-col class="py-0 pr-0">
              <h1 class="subtitle-2 black--text">
                {{ pasien.passportNumber }}
              </h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" class="py-0">
              <h1 class="subtitle-2 black--text">Tanggal Lahir</h1>
            </v-col>
            <div class="w-100 subtitle-2 black--text font-weight-black">:</div>
            <v-col class="py-0 pr-0">
              <h1 class="subtitle-2 black--text">
                {{ localDate(pasien.birthDate) }} ({{
                  yearOld(pasien.birthDate)
                }}
                tahun)
              </h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" class="py-0">
              <h1 class="subtitle-2 black--text">Jenis Kelamin</h1>
            </v-col>
            <div class="w-100 subtitle-2 black--text font-weight-black">:</div>
            <v-col class="py-0 pr-0">
              <h1 class="subtitle-2 black--text">
                {{ pasien.gender == "Male" ? "Laki-Laki" : "Perempuan" }}
              </h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" class="py-0">
              <h1 class="subtitle-2 black--text">No. Telp</h1>
            </v-col>
            <div class="w-100 subtitle-2 black--text font-weight-black">:</div>
            <v-col class="py-0 pr-0">
              <h1 class="subtitle-2 black--text">
                {{ pasien.phoneNumber }}
              </h1>
            </v-col>
          </v-row>

          <v-row class="mb-3">
            <v-col cols="4" class="py-0 mb-3">
              <h1 class="subtitle-2 black--text">Alamat</h1>
            </v-col>
            <div class="w-100 subtitle-2 black--text font-weight-black">:</div>
            <v-col class="py-0 pr-0">
              <h1 class="subtitle-2 black--text">
                {{ pasien.address }}
              </h1>
            </v-col>
          </v-row>

          <!-- <v-row class="mb-3">
            <v-col cols="4" class="py-0 mb-3">
              <h1 class="subtitle-2 black--text">Alamat Domisili</h1>
            </v-col>
            <div class="w-100 subtitle-2 black--text font-weight-black">:</div>
            <v-col class="py-0 pr-0">
              <h1 class="subtitle-2 black--text">
                {{ pasien.addressDomisili }}
              </h1>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col cols="4" class="py-0">
              <h1 class="subtitle-2 black--text font-weight-black">
                No Request Lab
              </h1>
            </v-col>
            <div class="w-100 subtitle-2 black--text font-weight-black">:</div>
            <v-col class="py-0 pr-0">
              <h1 class="subtitle-2 black--text font-weight-black">
                {{ pasien.requestLab }}
              </h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" class="py-0">
              <h1 class="subtitle-2 black--text">Tanggal Pemeriksaan</h1>
            </v-col>
            <div class="w-100 subtitle-2 black--text font-weight-black">:</div>
            <v-col class="py-0 pr-0">
              <h1 class="subtitle-2 black--text">
                {{ localDate(pasien.samplingDate) }}
              </h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" class="py-0">
              <h1 class="subtitle-2 black--text">Jenis Pemeriksaan</h1>
            </v-col>
            <div class="w-100 subtitle-2 black--text font-weight-black">:</div>
            <v-col class="py-0 pr-0">
              <h1
                class="subtitle-2 black--text"
                v-if="pasien.antigenResult != null"
              >
                ANTIGEN SARS-CoV-2
              </h1>
              <h1
                class="subtitle-2 black--text"
                v-if="pasien.pcrResult != null"
              >
                PCR SARS-CoV-2
              </h1>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" class="py-0">
              <h1 class="subtitle-2 black--text">Hasil Pemeriksaan</h1>
            </v-col>
            <div class="w-100 subtitle-2 black--text font-weight-black">:</div>
            <v-col class="py-0 pr-0">
              <h1
                class="subtitle-2 black--text"
                v-if="pasien.antigenResult != null"
              >
                {{ pasien.antigenResult }}
              </h1>
              <h1
                class="subtitle-2 black--text"
                v-if="pasien.pcrResult != null"
              >
                {{ pasien.pcrResult }}
              </h1>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="px-4" v-else>
        <v-row>
          <v-col cols="4">
            <v-img src="@/assets/logo.png" width="162"></v-img>
          </v-col>
          <v-col>
            <v-card-title>Kartu Pasien</v-card-title>
            <v-card-subtitle
              >Kartu ini sudah terverifikasi dalam sistem kami</v-card-subtitle
            >
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-card-text class="mt-7">
          <span class="subtitle-2">Sedang mengambil data pasien</span>
          <v-skeleton-loader
            type="list-item-three-line, list-item-three-line"
          ></v-skeleton-loader>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: true,
    };
  },
  computed: {
    pasien() {
      return this.$store.getters.pasien;
    },
  },
  methods: {
    localDate(date) {
      date = new Date(date);
      return Intl.DateTimeFormat("id-ID", { dateStyle: "long" }).format(date);
    },
    yearOld(date) {
      date = new Date(date);
      let today = new Date();
      let yearOld = today.getFullYear() - date.getFullYear();
      return yearOld;
    },
  },
  mounted() {
    let tokenId = window.atob(this.$route.params.tokenId);
    this.$store.dispatch("getPasien", tokenId);
  },
};
</script>

<style scoped>
.bg {
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
</style>
